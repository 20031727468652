<template>
  <nav class="page-sidebar" data-pages="sidebar">
    <div class="sidebar-header text-center">
      <img src="/images/logo.png" alt="logo" data-src="/images/logo.png" class="brand"
           data-src-retina="/images/logo.png" height="50">
    </div>
    <AppSidebar :input-items="items"></AppSidebar>
  </nav>
</template>
<script>
import AppSidebar from "@components/_common/AppSidebar";

export default {
  components: {AppSidebar},
  data() {
    return {
      items: [
        {
          routeName: this.$consts.ROUTES.HOTEL.DASHBOARD,
          label: this.$t('nav.dashboard'),
          iconSrc: '/icons/list.svg',
          activeKey: 'hotel.dashboard',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.HOTEL.BOOKING_LIST,
          label: this.$t('nav.Bookings'),
          iconSrc: '/icons/calendar.svg',
          activeKey: 'hotel.booking.list'
        },
        {
          routeName: this.$consts.ROUTES.HOTEL.INFO,
          label: this.$t('nav.Hotel info'),
          iconSrc: '/icons/hotel.svg',
          activeKey: 'hotel.info',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.HOTEL.HOTEL_ROOM_TYPE_LIST,
          label: this.$t('nav.hotel room type'),
          iconSrc: '/icons/list.svg',
          activeKey: 'hotel.room-types.list',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.HOTEL.HOTEL_FEE_RULE_LIST,
          label: this.$t('nav.hotel fee rule'),
          iconSrc: '/icons/money.svg',
          activeKey: 'hotel.fee-rules.list',
          children: []
        },
      ]
    }
  },
  methods: {
    clickLogout() {
      this.$auth.logout('hotelAuth');
    }
  }
}
</script>
