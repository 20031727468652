<template>
  <div class="sidebar-menu">
    <!-- BEGIN SIDEBAR MENU ITEMS-->
    <ul class="menu-items">
      <li v-for="item in items"
          :class="[item.boundaryClass, item.isActive ? (item.hasChild ? 'open active' : 'open active non-parent-active') : '']">
        <template v-if="item.clickHandle">
          <a href="#" @click.prevent="item.clickHandle">
            <span class="title">{{ item.label }}</span>
          </a>
          <span class="icon-thumbnail-img">
            <img :src="item.iconSrc"/>
           </span>
        </template>
        <template v-else-if="item.hasChild">
          <a href="javascript:;">
            <span class="title">{{ item.label }}</span>
            <span class="arrow"></span>
          </a>
          <span class="icon-thumbnail-img">
            <img :src="item.iconSrc"/>
           </span>
        </template>
        <template v-else>
          <router-link :to="{ name: item.routeName }">
            <span class="title">{{ item.label }}</span>
            <span v-if="item.hasChild" class=" arrow"></span>
          </router-link>
          <span :class="[item.isActive ? 'active ' : '', 'icon-thumbnail-img']">
            <img :src="item.iconSrc"/>
        </span>
        </template>
        <ul v-if="item.children && item.children.length" class="sub-menu">
          <li v-for="child in item.children" :class="[child.boundaryClass, child.isActive ? 'open active' : '']">
            <template v-if="child.clickHandle">
              <a href="#" @click.prevent="child.clickHandle">
                <span class="title">{{ child.label }}</span>
              </a>
              <span class="icon-thumbnail-img">
              <img :src="child.iconSrc"/>
             </span>
            </template>
            <template v-else>
              <router-link :to="{ name: child.routeName }">
                <span class="title">{{ child.label }}</span>
              </router-link>
              <span :class="'icon-thumbnail-img'">
            <img :src="child.iconSrc"/>
            </span>
            </template>
          </li>
        </ul>
      </li>
      <li class="guide"><a :href="guideUrl"
                           target="_blank">
        <span class="title app-text-bold" style="text-decoration: underline">マニュアル</span></a><span
        class="icon-thumbnail-img" style="padding-top: 10px !important;">
        <img style="width: 22px; height: 22px" src="/icons/questions.svg"></span>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  props: {
    inputItems: Array
  },
  data() {
    var guideUrl = '#';
    switch (this.$store.state.setting.authType) {
      case 'adminAuth': {
        guideUrl = 'https://docbase.io/posts/1988683/sharing/590d6a9d-7dc1-4372-9458-aee8e11e6cd5'
        break;
      }
      case 'csAuth': {
        guideUrl = 'https://docbase.io/posts/1988685/sharing/d7ad2327-0a2d-47f9-8f8e-3f9ed342826a'
        break;
      }
      case 'hotelAuth': {
        guideUrl = 'https://docbase.io/posts/1988690/sharing/5aef28c0-3340-422c-a783-1f12cf4353bb'
        break;
      }
      case 'taxiAuth': {
        guideUrl = 'https://docbase.io/posts/1988688/sharing/9c384d18-ff43-415d-8c71-ee3c410b0e7f'
        break;
      }
    }
    return {
      guideUrl: guideUrl,
    }
  },
  computed: {
    items() {
      var items = this.inputItems
      var newItems = [];
      _.forEach(items, (item) => {
        let hasChild = item.children && item.children.length > 0;
        let newItem = {
          ...item,
          hasChild,
          isActive: (item.activeKey === this.$route.meta.activeKey) && item.activeKey
        }
        if (hasChild) {
          _.forEach(item.children, (child, childIndex) => {
            let isActive = (child.activeKey && (child.activeKey === this.$route.meta.activeKey));
            if (!newItem.isActive) {
              newItem.isActive = isActive
            }
            newItem.children[childIndex].isActive = isActive
          })
        }
        newItems.push(newItem)
      })
      return newItems;
    }
  },
}
</script>
<style scoped="scss">
.guide {
  position: absolute;
  width: 100%;
  bottom: 2px;
  background: #00407b;
}
</style>
