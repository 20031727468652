<template>
  <nav class="page-sidebar" data-pages="sidebar">
    <div class="sidebar-header text-center">
      <img src="/images/logo.png" alt="logo" data-src="/images/logo.png" class="brand"
           data-src-retina="/images/logo.png" height="50">
    </div>
    <AppSidebar :input-items="items"></AppSidebar>
  </nav>
</template>
<script>
import AppSidebar from "@components/_common/AppSidebar";

export default {
  components: {AppSidebar},
  data() {
    return {
      items: [
        {
          routeName: this.$consts.ROUTES.TAXI.DASHBOARD,
          label: this.$t('nav.dashboard'),
          iconSrc: '/icons/list.svg',
          activeKey: 'taxi.dashboard',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.TAXI.BOOKING_LIST,
          label: this.$t('nav.Bookings'),
          iconSrc: '/icons/calendar.svg',
          activeKey: 'taxi.booking.list'
        },
        {
          routeName: this.$consts.ROUTES.TAXI.AVAILABLE_TAXI,
          label: this.$t('nav.Available taxi'),
          iconSrc: '/icons/available.svg',
          activeKey: 'taxi.available'
        },
        {
          routeName: this.$consts.ROUTES.TAXI.INFO,
          label: this.$t('nav.Taxi info'),
          iconSrc: '/icons/car.svg',
          activeKey: 'taxi.info',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.TAXI.TAXI_BRANCH_LIST,
          label: this.$t('nav.taxi branch'),
          iconSrc: '/icons/hotel.svg',
          activeKey: 'taxi.branchs.list',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.TAXI.TAXI_TYPE_LIST,
          label: this.$t('nav.taxi type list'),
          iconSrc: '/icons/list.svg',
          activeKey: 'taxi.types.list',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.TAXI.TAXI_FEE_RULE,
          label: this.$t('nav.taxi fee rule'),
          iconSrc: '/icons/money.svg',
          activeKey: 'taxi.fee-rules.list',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.TAXI.TOURIST_OPTION,
          label: this.$t('nav.tourist option'),
          iconSrc: '/icons/plus.svg',
          activeKey: 'taxi.tourist-options.list',
          children: []
        },
      ]
    }
  },
  methods: {
    clickLogout() {
      this.$auth.logout('taxiAuth');
    }
  }
}
</script>
