<template>
  <nav class="page-sidebar" data-pages="sidebar">
    <div class="sidebar-header text-center">
      <img src="/images/logo.png" alt="logo" data-src="/images/logo.png" class="brand"
           data-src-retina="/images/logo.png" height="50">
    </div>
    <AppSidebar :input-items="items"></AppSidebar>
  </nav>
</template>
<script>
import AppSidebar from "@components/_common/AppSidebar";

export default {
  components: {AppSidebar},
  data() {
    return {
      items: [
        {
          routeName: this.$consts.ROUTES.CS.DASHBOARD,
          label: this.$t('nav.dashboard'),
          iconSrc: '/icons/list.svg',
          activeKey: 'cs.dashboard',
          children: []
        },
        {
          routeName: this.$consts.ROUTES.CS.BOOKING_LIST,
          label: this.$t('nav.Bookings'),
          iconSrc: '/icons/calendar.svg',
          activeKey: 'cs.booking.list'
        },
        {
          routeName: this.$consts.ROUTES.CS.AVAILABLE_TAXI,
          label: this.$t('nav.Available taxi'),
          iconSrc: '/icons/available.svg',
          activeKey: 'cs.taxi.available'
        },
        {
          routeName: this.$consts.ROUTES.CS.ANALYSIS,
          label: this.$t('nav.Analysis'),
          iconSrc: '/icons/chart.svg',
          activeKey: 'cs.analysis'
        },
      ]
    }
  },
  methods: {
    clickLogout() {
      this.$auth.logout('csAuth');
    }
  }
}
</script>
