<template>
  <div class="layout-container">
    <template v-if="authType === 'adminAuth'">
      <SidebarAdmin/>
    </template>
    <template v-if="authType === 'csAuth'">
      <SidebarCS/>
    </template>
    <template v-if="authType === 'hotelAuth'">
      <SidebarHotel/>
    </template>
    <template v-if="authType === 'taxiAuth'">
      <SidebarTaxi/>
    </template>
    <div class="page-container">
      <div class="page-content-wrapper">
        <div class="content">
          <div class="jumbotron header-jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-0 sm-p-r-0 bg-white breadcrumb-container">
              <div class="inner header-inner">
                <Breadcrumbs></Breadcrumbs>
              </div>
            </div>
          </div>
          <div>
            <router-view :key="$route.path"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@components/_partials/Breadcrumbs";
import SidebarAdmin from "@components/admin/_partials/Sidebar";
import SidebarCS from "@components/cs/_partials/Sidebar";
import SidebarHotel from "@components/hotel/_partials/Sidebar";
import SidebarTaxi from "@components/taxi/_partials/Sidebar";

export default {
  data() {
    return {
      authType: this.$store.state.setting.authType
    }
  },
  components: {
    SidebarAdmin,
    SidebarCS,
    SidebarHotel,
    SidebarTaxi,
    Breadcrumbs,
  },
  methods: {
    clickLogout() {
      this.logout();
    },
  },
  mounted() {
    try {
      $.Pages.init();
      if ($('body').hasClass('sidebar-open')) {
        $('[data-pages="sidebar"]').addClass('visible')
      }
      $('body').on('submit', 'form', function () {
        $(this).find('input').blur();
      })
    } catch (e) {

    }
  },
}
</script>
