<template>
  <div>
    <div class="row align-items-center app-pg-menu">
      <a href="#" class="btn-link toggle-sidebar d-lg-none pg pg-menu" data-toggle="sidebar"></a>
    </div>
    <ol class="breadcrumb">
      <li v-for="breadcrumb in breadcrumbsComputed"
          :class="$route.name === breadcrumb.routeName ? 'breadcrumb-item active' : 'breadcrumb-item'">
        <template v-if="$route.name !== breadcrumb.routeName">
          <router-link :to="{name: breadcrumb.routeName}">
            {{ breadcrumb.name }}
          </router-link>
        </template>
        <template v-else>
          {{ breadcrumb.name }}
        </template>
      </li>
    </ol>
    <div style="position: absolute; top: 10px; right: 40px" class="d-flex align-items-center">
      <div class="always-show pull-left fs-14 font-heading d-lg-block d-none">
        <span class="semi-bold app-text">{{ name }}</span>
      </div>
      <div class="always-show dropdown pull-right d-lg-block d-none">
        <button class="profile-dropdown-toggle cursor" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                    <span class="thumbnail-wrapper d39 circular inline">
                        <img src="/images/avatar.svg" alt=""
                             data-src="/images/avatar.svg"
                             data-src-retina="/images/avatar.svg" width="32"
                             height="32">
                    </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
          <router-link :to="{name: profileRoute}" class="clearfix bg-master-lighter dropdown-item">
            <span class="pull-left">{{ $t('nav.profile') }}</span>
            <span class="pull-right"><i class="fa fa-user"></i></span>
          </router-link>
          <a @click.prevent="clickLogout" href="#" class="clearfix bg-master-lighter dropdown-item">
            <span class="pull-left">{{ $t('nav.logout') }}</span>
            <span class="pull-right"><i class="fa fa-sign-out"></i></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import config from "@/config";

export default {
  computed: mapState({
    setting: 'setting',
    breadcrumbsComputed() {
      let breadcrumbs = this.setting.breadcrumbs;
      let breadcrumbParams = this.setting.breadcrumbParams;
      let newBreadcrumbs = []
      _.forEach(breadcrumbs, (breadcrumb, index) => {
        let breadcrumbName = breadcrumb.name;
        let regexs = breadcrumbName.match(/\{\w+\}/g)
        if (regexs) {
          _.forEach(regexs, regex => {
            let newBreadcrumName = breadcrumbParams[regex.slice(1, -1)];
            breadcrumbName = breadcrumbName.replace(regex, newBreadcrumName ? newBreadcrumName : this.$t('common.loading'));
          })
        }
        newBreadcrumbs.push({...breadcrumb, name: breadcrumbName})
      })

      document.title = config.APP_NAME + ' | ' + newBreadcrumbs.map(breadcrumb => breadcrumb.name).join(' | ')

      return newBreadcrumbs;
    },
    name() {
      const user = this.$auth.user(this.authKey);
      return user ? user.name : ''
    },
    authKey() {
      return this.$store.state.setting.authType
    },
    profileRoute() {
      switch (this.authKey) {
        case 'adminAuth': {
          return this.$consts.ROUTES.ADMIN.PROFILE
        }
        case 'csAuth': {
          return this.$consts.ROUTES.CS.PROFILE
        }
        case 'hotelAuth': {
          return this.$consts.ROUTES.HOTEL.PROFILE
        }
        case 'taxiAuth': {
          return this.$consts.ROUTES.TAXI.PROFILE
        }
      }
    }
  }),
  methods: {
    clickLogout() {
      this.$auth.logout(this.authKey);
    },
  }

}
</script>
