<template>
  <nav class="page-sidebar" data-pages="sidebar">
    <div class="sidebar-header text-center">
      <img src="/images/logo.png" alt="logo" data-src="/images/logo.png" class="brand"
           data-src-retina="/images/logo.png" height="50">
    </div>
    <AppSidebar :input-items="items"></AppSidebar>
  </nav>
</template>
<script>
import AppSidebar from "@components/_common/AppSidebar";

export default {
  components: {AppSidebar},
  data() {
    return {
      items: [
        {
          routeName: this.$consts.ROUTES.ADMIN.DASHBOARD,
          label: this.$t('nav.dashboard'),
          iconSrc: '/icons/list.svg',
          activeKey: 'admin.dashboard',
          children: []
        },
        {
          label: this.$t('nav.hotel management'),
          iconSrc: '/icons/hotel.svg',
          children: [
            {
              routeName: this.$consts.ROUTES.ADMIN.HOTEL_LIST,
              label: this.$t('nav.hotel'),
              iconSrc: '/icons/submenu.svg',
              activeKey: 'admin.hotel.list'
            },
            {
              routeName: this.$consts.ROUTES.ADMIN.USERS_HOTEL_LIST,
              label: this.$t('nav.hotel account'),
              iconSrc: '/icons/submenu.svg',
              activeKey: 'admin.users.hotel_user',
            },
          ]
        },
        {
          label: this.$t('nav.taxi company management'),
          iconSrc: '/icons/car.svg',
          children: [
            {
              routeName: this.$consts.ROUTES.ADMIN.TAXI_LIST,
              label: this.$t('nav.taxi company'),
              iconSrc: '/icons/submenu.svg',
              activeKey: 'admin.taxi.list'
            },
            {
              routeName: this.$consts.ROUTES.ADMIN.USERS_TAXI_LIST,
              label: this.$t('nav.taxi account'),
              iconSrc: '/icons/submenu.svg',
              activeKey: 'admin.users.taxi_user'
            }
          ]
        },
        {
          label: this.$t('nav.master setting'),
          iconSrc: '/icons/list.svg',
          children: [
            {
              routeName: this.$consts.ROUTES.ADMIN.START_POINT_LIST,
              label: this.$t('nav.start point'),
              iconSrc: '/icons/submenu.svg',
              children: [],
              activeKey: 'admin.settings.start_point'
            },
            {
              routeName: this.$consts.ROUTES.ADMIN.FEE_CHILD_RULE,
              label: this.$t('nav.fee child rule'),
              iconSrc: '/icons/submenu.svg',
              children: [],
              activeKey: 'admin.settings.fee_child_rule'
            },
            {
              routeName: this.$consts.ROUTES.ADMIN.AREA,
              label: this.$t('nav.area'),
              iconSrc: '/icons/submenu.svg',
              children: [],
              activeKey: 'admin.settings.area'
            },
          ]
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.BOOKING_LIST,
          label: this.$t('nav.Bookings'),
          iconSrc: '/icons/calendar.svg',
          activeKey: 'admin.booking.list'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.AVAILABLE_TAXI,
          label: this.$t('nav.Available taxi'),
          iconSrc: '/icons/available.svg',
          activeKey: 'admin.taxi.available'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.ANALYSIS,
          label: this.$t('nav.Analysis'),
          iconSrc: '/icons/chart.svg',
          activeKey: 'admin.analysis'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.USERS_ADMIN_LIST,
          label: this.$t('nav.admin account'),
          iconSrc: '/icons/setting.svg',
          activeKey: 'admin.users.admin_user'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.USERS_CS_LIST,
          label: this.$t('nav.cs account'),
          iconSrc: '/icons/setting.svg',
          activeKey: 'admin.users.cs_user'
        },
      ]
    }
  },
  methods: {
    clickLogout() {
      this.$auth.logout('adminAuth');
    }
  }
}
</script>
